<template>
  <div>
    <transition name="chat-slide">
      <div
        class="chat-content chat-container"
        id="chat-container"
        v-if="showChat"
      >
        <div class="chat-header">
          <div class="chat-img-text" style="display: flex; align-items: center;">
            <img
              src="../../public/images/logo1.png"
              class="me-2 chat-header-avatar"
              alt="logo"
              style="width: 30%;"
            />
            <div class="chat-header-title">
                <h3>Copilot</h3>
                </div>
          </div>
        </div>

        <div class="chat-messages" ref="messageContainer">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="[
              'message',
              message.sender === 'user' ? 'user-message' : 'copilot-message',
            ]"
          >
            <div class="message-content">
              <div class="message-label">
                {{ message.sender === 'user' ? 'You' : 'Copilot' }}
              </div>            
              <div class="message-bubble">
                {{ message.text }}
              </div>
            </div>
            <div class="message-timestamp">{{ message.timestamp }}</div>
          </div>
        </div>

        <div class="button-container">
          <button @click="addSampleMessage('Create Welcome Menu')">Create Welcome Menu</button>
          <button @click="addSampleMessage('Add Menu for Gender')">Add Menu for Gender</button>
          <button @click="addSampleMessage('Run Emulator')">Run Emulator</button>
          <button @click="addSampleMessage('Deploy Changes')">Deploy Changes</button>
          <button @click="addSampleMessage('Add Api Call to Main Menu')">Add Api Call to Main Menu</button>
        </div>

        <div class="chat-footer" style="padding: 4px;">
          <div class="input-container">
            <textarea
              ref="messageInput"
              class="message-input"
              id="message-input"
              v-model="newMessage"
              @keydown.enter.exact.prevent="sendMessage"
              @keydown.enter.shift.exact.prevent="newMessage += '\n'"
              rows="1"
              placeholder="Type a message... (Shift + Enter for new line)"
              @input="autoResize"
            ></textarea>
          </div>
          <button class="send-button" @click="sendMessage">
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </transition>
    <div class="chat-button-container">
      <!-- Chat Button with Tooltip -->
      <button
        @click="handleChatToggle"
        class="chat-button"
        :class="{ 'is-active': isActive }"
        aria-label="Toggle copilot chat"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="chat-icon"
          :class="{ 'rotate': showChat }"
        >
          <path
            d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
          />
        </svg>

        <!-- Tooltip -->
        <span class="tooltip">Copilot</span>
      </button>
    </div>
  </div>
</template>

<script>
import baseUrl from "@/shared/baseUrl";
export default {
  name: "CopilotChat",
  data() {
    return {
      appId: this.$route.params.id,
      isActive: false,
      messages: [],
      newMessage: "",
      showChat: false
    };
  },
  methods: {
    handleChatToggle() {
      this.showChat = !this.showChat;
    },
    sendMessage() {
      if (!this.newMessage.trim()) return;
      this.messages.push({
        text: this.newMessage,
        sender: "user",
        timestamp: new Date().toLocaleTimeString(),
      });
      const payload ={
        appId:this.appId,
        prompt: this.newMessage
      }
      
      baseUrl.post("/copilot", payload).then((res) => {
        const copilotMsg  = res.data.message;
        console.log("Response from copilot api>>",res.data)
        this.messages.push({
          text: copilotMsg,
          sender: "copilot",
          timestamp: new Date().toLocaleTimeString(),
        });
          if (res.data.status == "00") {
            this.executeFrontendActions();
          }
          this.scrollToBottom();
        });
 
      this.newMessage = "";
      this.$refs.messageInput.style.height = 'auto';
    },
    executeFrontendActions(){
        //TODO implement frontendAction calls;
        console.log("Executing frontend functions")
    },
    addSampleMessage(sampleText) {
      this.newMessage = sampleText + ' ';
      this.$refs.messageInput.focus();
      this.sendMessage();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    autoResize(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';      
      // Limit the height to 150px and enable scrolling if content is larger
      if(textarea.scrollHeight > 150) {
        textarea.style.height = '150px';
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    },
    formatMessage(text) {
      return text
        .replace(/\n/g, '<br>')
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.*?)\*/g, '<em>$1</em>')
        .replace(/`(.*?)`/g, '<code>$1</code>');
    }
  },
  mounted() {
     this.$nextTick(() => {
      if (this.$refs.messageInput) {
        this.autoResize({ target: this.$refs.messageInput });
      }
    });
  }
};
</script>

<style scoped>
/* Existing styles remain the same */
.chat-button-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1034;
}

.chat-button {
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: #4a5568;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.chat-button:hover {
  background-color: #2d3748;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.chat-button.is-active {
  /* background-color: #2d3748; */
  background-color: #4CAF50;
}

.chat-icon {
  color: white;
  transition: transform 0.3s ease;
}
.chat-icon.rotate {
  transform: rotate(180deg);
}

.chat-button:hover .chat-icon {
  transform: scale(1.1);
}

.tooltip {
  position: absolute;
  background-color: #1a202c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  white-space: nowrap;
  right: 120%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.tooltip::before {
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 6px 0 6px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #1a202c;
}

.chat-button:hover .tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) translateX(-5px);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 85, 104, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(74, 85, 104, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(74, 85, 104, 0);
  }
}

.chat-button:not(.is-active) {
  animation: pulse 2s infinite;
}




/* Animation for chat container */
.chat-slide-enter-active,
.chat-slide-leave-active {
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
}

.chat-slide-enter-from,
.chat-slide-leave-to {
  transform: scale(0.95) translateY(30px);
  opacity: 0;
}

.chat-slide-enter-to,
.chat-slide-leave-from {
  transform: scale(1) translateY(0);
  opacity: 1;
}
/* Chat Container Styles */
.chat-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1033;
  max-width: 500px;
  width: 100%;
  height: calc(100vh - 200px);
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

/* Messages Styles */
.chat-messages {
    flex: 1;
  /* height: 500px; */
  height: auto; /* Remove fixed height */
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Updated and new styles for messages and labels */
.message {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-bottom: 20px;
  animation: message-pop 0.3s ease-out;
}

@keyframes message-pop {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-label {
  font-size: 0.75rem;
  margin-bottom: 4px;
  font-weight: 500;
}

.user-message {
  align-self: flex-end;
}

.user-message .message-label {
  color:  #067c0a;
  align-self: flex-end;
  margin-right: 4px;
}

.copilot-message {
  align-self: flex-start;
}

.copilot-message .message-label {
  color: #1e88e5;
  align-self: flex-start;
  margin-left: 4px;
}

/* Message bubble styles to handle formatted text */
.message-bubble {
  padding: 12px 16px;
  border-radius: 16px;
  position: relative;
  word-wrap: break-word;
  max-width: 100%;
  white-space: pre-wrap;
}

.message-bubble :deep(code) {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
}

.user-message .message-bubble :deep(code) {
  background-color: rgba(255, 255, 255, 0.2);
}

.message-bubble :deep(strong) {
  font-weight: 600;
}

.message-bubble :deep(em) {
  font-style: italic;
}

.user-message .message-bubble {
  background-color: #067c0a;
  color: white;
  border-bottom-right-radius: 4px;
}

.copilot-message .message-bubble {
  background-color: #0b4e7e;
  color: #ebebf3;
  border-bottom-left-radius: 4px;
}



/* Timestamp Styles */
.message-timestamp {
  font-size: 0.75rem;
  color: #666;
  margin-top: 4px;
  margin-bottom: 4px;
}

.user-message .message-timestamp {
  text-align: right;
}

.copilot-message .message-timestamp {
  text-align: left;
}

/* Header and Footer Styles */
.chat-header {
  background-color: #1e1e2f;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* position: relative; */
}




/* Updated chat footer and input styles */
.chat-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center; /* Center items vertically */
  padding: 8px;
  border-top: 1px solid #e9e9e9;
  margin-bottom: 0;

  box-sizing: border-box;
}

.input-container {
  flex-grow: 1;
  margin-right: 12px;
  background-color: #f8f9fa;
  border-radius: 20px;
  padding: 8px 14px;
}

.message-input {
  width: 100%;
  min-height: 24px;
  max-height: 150px; /* Maximum height before scrolling */
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #201e1e;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  overflow-y: auto;
}

.message-input::-webkit-scrollbar {
  width: 6px;
}

.message-input::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.message-input::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.message-input::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 

.send-button {
  background-color: transparent;
  color: #1e1e2f;
  font-size: 20px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  align-self: center; /* Center the send button vertically */
}

.send-button:hover {
  color:  #067c0a;
  transform: scale(1.05);
}
.send-button:disabled {
  cursor: not-allowed;
  transform: none;
}

.button-container {
 

  padding: 8px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;

}

.button-container button {
  background-color:  #067c0a;; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 20px; /* Make buttons more rounded */
  padding: 4px 8px; /* Adjust padding for smaller height */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 11px; /* Smaller text size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  height: auto; /* Allow height to adjust based on text */
}

.button-container button:hover {
  background-color: #067c0a; /* Darker shade on hover */
}

/* Optional: Add a balloon-like effect */
.button-container button:before {
  content: '';
  position: absolute;
  bottom: 100%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #067c0a; /* Same color as button */
}


 
</style>
