<template>
   <div>
       <body>
        <div class="container-scroller">
            <!-- partial:partials/_navbar.html -->
          <Topnav/>
            <!-- partial -->
            <div class="container-fluid page-body-wrapper">
            <!-- Sidebar -->
            <!-- partial -->
            <!-- partial:partials/_sidebar.html -->
         <Sidebar/>
         <CopilotToggler @toggleCopilotChat="toggleChat"/>
             <!-- partial -->
            <div class="main-panel">
                <div class="content-wrapper">
               <router-view></router-view>
                </div>
                <div class="modal fade" id="exampleModal-4" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-body">
                         <div class="pixel-loader"></div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <!-- main-panel ends -->
            </div>
            <!-- page-body-wrapper ends -->
        </div>
        <!-- container-scroller -->
    </body>
   </div>
</template>
<script>
import Sidebar from '@/components/appSidebar.vue'
import Topnav from '@/components/topnav.vue'
import CopilotToggler from '@/components/copilotTogglerComponent'
  import api from "@/shared/menuApi";
 export default {
    components: {
        Sidebar,
        Topnav,
        CopilotToggler,
     },
    data(){
        return{
           id:this.$route.params.id,
           showChat: false
        }
    },
    methods: {
        toggleChat() {
            this.showChat = !this.showChat;
         },
        validateJwt(){
            const vm=this;
            api
            .doPost("/api/validateJwt", {})
            .then((response) => {
                    if (response.status !== "00") {
                        this.toast.error("Session Expired");
                        //localStorage.removeItem('token');
                        setTimeout(function () {
                            console.log("Going to login");
                            vm.$router.push('/login');
                        }, 1000);
                    }  
            })
            .catch((error) => {
                this.toast.error("Faile to verify User ");
                        setTimeout(function () {
                            vm.$router.push('/login');
                        }, 1000);
            });
        }

  },
    async mounted(){
        this.validateJwt();
        let response=await api.getAppById(this.$route.params.id);
        if(response.status=="00"){
            let  appData=response.data;
            this.$store.commit('setCurrentApp',appData);
        } 
        setTimeout(function(){
            if (window.Tawk_API && window.Tawk_API.hideWidget) {
            // Hide TawkTo Widget  
              window.Tawk_API.hideWidget();
           }
        },1000);
       
    }  
}
</script>

<style>
#closeBtn{
    position:absolute;
    right:10px;
    top:10px;
    z-index:1;
}
.button{
    position:absolute;
    right:10px;
    top:10px;
    z-index:1;
    padding: 60px;
}
select{
    background-color: #2b2e4c;
    color: #fff;
    border:1px solid #424351;
}
#right{
    float: right;
    z-index: 9999;
}
article{
  display: none;
}
article.on{
  display: block;
}
textarea{
    color: #fff;
}
#codeeditor{
    height:500px;
    width:100px;
}
</style>